import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import API from '../../api'
import Tempo from "./Tempo";
import NumeroTappa from "./NumeroTappa";
import TappaPreviewList from "./TappaPreviewList";

import icon from '../../../icons/marker-icon.png';
import 'leaflet/dist/leaflet.css';

let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const PercorsoDetail = (props) => {

    const { t } = useTranslation();

    const params = useParams()
    const [percorso, setPercorso] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tappaFilter, setTappaFilter] = useState()

    useEffect(() => {
        async function fetchPercorsoHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            setIsLoading(true);
            // const filteredPropsArray = props.items.filter(item => item.id === params.percorsiId)
            // setTappaFilter(filteredPropsArray[0])
                let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/percorso_museale/${params.percorsiId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)
                const dataPercorso = await response.data;
                const dataPercorsoArray = [dataPercorso]

                const transformedPercorso = dataPercorsoArray.map(percorsoData => {
                    // console.log(percorsoData.included[1].attributes.uri.url)
                    return{
                        id: percorsoData.data.id,
                        title: percorsoData.data.attributes.title,
                        tempoMedio: percorsoData.data.attributes.field_tempo_medio_di_percorrenza,
                        tappa: percorsoData.data.relationships.field_tappe.data,
                        body: percorsoData.data.attributes.body,
                        preview: percorsoData.included[1].attributes.uri.url,
                    };
                });

            // setPercorso((prevState => {
            //     return [prevState, ...transformedPercorso]
            // }));
                setPercorso(transformedPercorso);
                setIsLoading(false);

        }

        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });

        myPromise
            .then(fetchPercorsoHandler)

    }, []);
    console.log(percorso.preview)
    return(

        <div>
            <section>
                {!isLoading && percorso.length > 0 &&
                    <div key={percorso.id}>
                        <div className={`relative overflow-hidden cursor-pointer shadow-up`}>
                                <img className=" object-cover w-full h-28 rounded-br-none rounded-bl-none"
                                     src={`https://gibellina.bbsitalia.com/${percorso[0].preview}`}
                                     alt="Flower and sky"/>

                            <div className="absolute bottom-0 left-0 px-6 py-4">
                                <h3 className="text-base tracking-tight text-white font-medium">
                                    {(percorso[0].title === null || percorso[0].title === undefined) ? '' : percorso[0].title}
                                </h3>
                            </div>
                        </div>
                        <div className='flex space-x-1 py-4 px-6'>
                            <Tempo
                                item={(percorso[0] === null || percorso[0] === undefined) ? '' : percorso[0].tempoMedio}
                            />
                            <NumeroTappa
                                item={(percorso[0] === null || percorso[0] === undefined) ? '' : percorso[0].tappa.length}
                            />
                        </div>
                        <TappaPreviewList
                            items={(percorso[0] === null || percorso[0] === undefined) ? '' : percorso[0].tappa}
                        />
                        {/*<div className='tappe-list'>*/}
                        {/*    { filteredPropsArray[0].map((propsTappaList, i) => (*/}
                        {/*        {propsTappaList}*/}
                        {/*    ))}*/}
                        {/*    {filteredPropsArray[0].tappa}*/}
                        {/*</div>*/}
                    </div>
                }
            </section>
        </div>
    );
}

export default PercorsoDetail;
