import React from "react";
import Spinner from "../../UI/Spinner";
// import {Route, Routes} from "react-router-dom";

const CardImgHeader = (props) => {
    console.log(props)
    return <div className="grid w-full">

        <div className={`relative overflow-hidden shadow-lg cursor-pointer shadow-up ${props.imgRounded}`}>
            {/*<Routes>*/}
                {/*<Route path="/novita/eventi/:eventId" element={*/}
                    <img className="object-cover w-full h-72 min- lg:h-96"
                         src={`https://gibellina.bbsitalia.com/${props.preview}`}
                         alt="Flower and sky"/>
                {/*}/>*/}

                {/*<Route path="/novita/notizie/:newsId" element={*/}
                {/*    <img className="object-cover w-full h-48"*/}
                {/*         src={`https://gibellina.bbsitalia.com/${props.preview}`}*/}
                {/*         alt="Flower and sky"/>*/}
                {/*}/>*/}
                {/*<Route path="/scopri/musei/list/:museiId" element={*/}
                {/*    <img className="object-cover w-full h-48"*/}
                {/*         src={`https://gibellina.bbsitalia.com/${props.preview}`}*/}
                {/*         alt="Flower and sky"/>*/}
                {/*}/>*/}

            {/*</Routes>*/}


            {props.isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
            {!props.isLoading && props.data.length === 0 && <p>No museiImage data</p>}

            <div className="absolute bottom-0 left-0 px-6 py-4 lg:px-24">
                <small className='text-white uppercase lg:text-base '>{props.category}</small>
                <small className='text-white uppercase lg:text-base '>{props.tag}</small>
                {props.textSmall === true &&
                    <h4 className="mb-3 text-l font-normal tracking-tight text-white">
                        {(props.title === null || props.title === undefined) ? '' : props.title}
                    </h4>
                }

                {!props.textSmall === true &&
                    <h1 className="mb-3 text-3xl lg:text-5xl font-normal tracking-tight text-white font-medium">
                        {(props.title === null || props.title === undefined) ? '' : props.title}
                    </h1>
                }
                {/*<p className="leading-normal text-gray-100">Lorem ipsum dolor, sit amet cons ectetur adipis icing elit.*/}
                {/*    Praesen tium, quibusdam facere quo laborum maiores sequi nam tenetur laud.</p>*/}
            </div>
        </div>
    </div>
};

export default CardImgHeader;
