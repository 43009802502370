import React, { useEffect, useState } from "react";
import PercorsiItem from "./PercorsiItem";
// import { Swiper, SwiperSlide } from 'swiper/react';

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    Circle,
    LayersControl,
    LayerGroup
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

import icon from "../../UI/currentPositionMarker";
import L from "leaflet";

import "swiper/css";
import { Route, Routes } from "react-router-dom";
import PercorsoDetail from "./PercorsoDetail";
import TestMap from "./testMap";
import MapButton from "../../UI/MapButton";
import Opere2 from "./Opere";
import ListButton from "../../UI/ListButton";
import Musei from "../../scopri/musei/Musei";
import DoveMangiare from "../../scopri/dove-mangiare/DoveMangiare";
import DoveDormire from "../../scopri/dove-dormire/DoveDormire";
import Servizi from "../../scopri/servizi/Servizi";
import MuseiPreview from "../../scopri/musei/MuseiPreview";
import API from "../../api";
import OperePreview from "./OperePreview";
import {useTranslation} from "react-i18next";
//
// const markerGroup = [
//     {
//         latitude: 45.5871295335506,
//         longitude: 10.096022957691819
//     },
//     {
//         latitude: 45.5871295335506,
//         longitude: 10.080022957691819
//     },
//     {
//         latitude: 45.5871295335506,
//         longitude: 10.091022957691819
//     }
// ];



let markerArray=[]

function MarkerDistance() {

    const { t } = useTranslation()


    const [percorsi, setPercorsi] = useState([]);

    useEffect(() => {
        async function fetchPercorsiHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')
            const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/opere?include=field_immagine_anteprima.field_media_image`)
            const dataPercorsi = await response.data;
            // console.log(dataPercorsi)
            console.log(dataPercorsi)
            if(dataPercorsi){
                const transformedPercorsi = dataPercorsi.data.map(percorsiData => {
                    return{
                        id: percorsiData.id,
                        title: percorsiData.attributes.title,
                        body: percorsiData.attributes.body,
                        latitude: percorsiData.attributes.field_latitudine,
                        longitude: percorsiData.attributes.field_longitudine,
                        previewId: percorsiData.relationships.field_immagine_anteprima.data.id
                    };
                });
                setPercorsi(transformedPercorsi);
            }
            return false
        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });
        myPromise
            .then(fetchPercorsiHandler)
    }, []);

    console.log(percorsi)


    const map = useMap();

    var content = L.layerGroup().addTo(map);

    map.locate().on("locationfound", function (e) {
        // const opereInterval = setInterval(() => {



        // content.clearLayers();
        // setPosition(e.latlng);
        // map.flyTo([localStorage.getItem('current-lat'), localStorage.getItem('current-long')]);
        map.flyTo(e.latlng);
        markerArray = []

        console.log(percorsi)

        percorsi.map((markerlist, i) => {
            // console.log(markerlist)
            // let fromLatLng = L.latLng([45.5871295335506, 10.096022957691819])
            console.log([localStorage.getItem('current-lat'), localStorage.getItem('current-long')])
            let fromLatLng = L.latLng(e.latlng);

            let toLatLng = L.latLng(markerlist.latitude, markerlist.longitude);
            let dis = fromLatLng.distanceTo(toLatLng);

            let marker = new L.Marker([
                markerlist.latitude,
                markerlist.longitude
            ]);
            markerArray.push({latitude: markerlist.latitude, longitude: markerlist.longitude, distance: dis, body: markerlist.body, id: markerlist.id, previewId: markerlist.previewId, title: markerlist.title})

            // markerArray = [{}]

            // if (dis <= 300) {
            //     markerArray = [{latitude: markerlist.latitude, longitude: markerlist.longitude}]
            // content.addLayer(marker)

            // content.addTo(marker);
            // console.log(`distanza ${dis}`);
            // console.log(`a ${toLatLng} `)

            // marker.addTo(content)
            // }
            if (dis >= 300) {

                // content.removeLayer(content._leaflet_id)

                // content.removeLayer(marker);
            }
        });

        // content.addTo(map);
        // }, 5000);
        // return () => {
        //     clearInterval(opereInterval);
        // };

    });

}

function LocationMarker() {
    const [position, setPosition] = useState(null);
    // const [markerArray, setMarkerArray] = useState();


    const map = useMap();


    useEffect(() => {


        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            setPosition([position.coords.latitude, position.coords.longitude])
            localStorage.setItem('current-position', [position.coords.latitude, position.coords.longitude])
            localStorage.setItem('current-lat', [position.coords.latitude])
            localStorage.setItem('current-long', [position.coords.longitude])
            // map.flyTo(position);
            // console.log(localStorage.getItem('current-position'))

        });
        const opereInterval1 = setInterval(() => {

            navigator.geolocation.getCurrentPosition(function(position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                setPosition([position.coords.latitude, position.coords.longitude])
                localStorage.setItem('current-position', [position.coords.latitude, position.coords.longitude])
                localStorage.setItem('current-lat', [position.coords.latitude])
                localStorage.setItem('current-long', [position.coords.longitude])
                // map.flyTo(position);
                // console.log(localStorage.getItem('current-position'))

            });

        }, 2000);



        return () => {
            clearInterval(opereInterval1);
        };


    }, [navigator]);


    let radius = 300;
    // let markerArrayF = [...new Set(markerArray)];

    console.log(markerArray)
    const markerResult = markerArray.filter((markerList, i) => markerList.distance <= radius);

    console.log(markerResult)


    //
    // const [opere, setOpere] = useState([]);
    // const [isLoading, setIsLoading] = useState(false)
    //
    // useEffect(() => {
    //     async function fetchOpereHandler() {
    //         const currentLanguageCode = localStorage.getItem('i18nextLng')
    //         setIsLoading(true);
    //         const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/opere?include=field_immagine_anteprima.field_media_image`)
    //         const dataOpere = await response.data;
    //         console.log(dataOpere)
    //         if(dataOpere){
    //             const transformedOpere = dataOpere.data.map(opereData => {
    //                 return{
    //                     id: opereData.id,
    //                     title: opereData.attributes.title,
    //                     body: opereData.attributes.body,
    //                     latitude: opereData.attributes.field_latitudine,
    //                     longitude: opereData.attributes.field_longitudine,
    //                     previewId: opereData.relationships.field_immagine_anteprima.data.id
    //                 };
    //             });
    //             setOpere(transformedOpere);
    //             setIsLoading(false);
    //         }
    //         return false
    //     }
    //     const myPromise = new Promise((resolve, reject) => {
    //         resolve('foo');
    //     });
    //     myPromise
    //         .then(fetchOpereHandler)
    // }, []);

    return position === null ? null : (
        <>

            {/*<LayersControl position="topright">*/}
            {/*    <LayersControl.Overlay name="Opere vicine" checked>*/}
            {/*        <LayerGroup>*/}
                        { markerResult.map((markerList, i) => (
                            <Marker
                                key={i}
                                index={i}
                                position={[(markerList.latitude === null || markerList.latitude === undefined) ? '' : markerList.latitude, (markerList.longitude === null || markerList.longitude === undefined) ? '' : markerList.longitude]}
                            >
                                <Popup>
                                    <OperePreview
                                        // key={i}
                                        // title={opereList.title}
                                        // previewId={opereList.previewId}
                                        // body={opereList.body}
                                        // id={opereList.id}
                                        items={markerList}
                                        // markerId={markerId}
                                    />
                                </Popup>
                            </Marker>
                        ))}
                        <MarkerDistance/>
                        <Circle center={position} radius={radius} />
                        {/*{*/}
                        {/*    circle.addTo(map)*/}
                        {/*}*/}
                        <Marker position={position} icon={icon}></Marker>
                    {/*</LayerGroup>*/}
                {/*</LayersControl.Overlay>*/}
            {/*</LayersControl>*/}
        </>
    );

}

const PercorsiList = (props) => {


    //
    // const getPosition = () => {
    //     const options = {
    //         enableHighAccuracy: true,
    //         timeout: 5000,
    //         maximumAge: 0
    //     };
    //
    //
    //     function success(pos) {
    //         const crd = pos.coords;
    //
    //         console.log('Your current position is:');
    //         console.log(`Latitude : ${crd.latitude}`);
    //         console.log(`Longitude: ${crd.longitude}`);
    //         console.log(`More or less ${crd.accuracy} meters.`);
    //     }
    //
    //     function error(err) {
    //         console.warn(`ERROR(${err.code}): ${err.message}`);
    //     }
    //
    //     navigator.geolocation.getCurrentPosition(success, error, options);
    // }

    // const watchPosition = () => {
    //     let id;
    //     let target;
    //     let options;
    //
    //     function success(pos) {
    //         const crd = pos.coords;
    //
    //         if (target.latitude === crd.latitude && target.longitude === crd.longitude) {
    //             console.log('Congratulations, you reached the target');
    //             navigator.geolocation.clearWatch(id);
    //         }
    //     }
    //
    //     function error(err) {
    //         console.warn('ERROR(' + err.code + '): ' + err.message);
    //     }
    //
    //     target = {
    //         latitude : 0,
    //         longitude: 0
    //     };
    //
    //     options = {
    //         enableHighAccuracy: true,
    //         timeout: 5000,
    //         maximumAge: 0
    //     };
    //
    //     id = navigator.geolocation.watchPosition(success, error, options);
    // }
    //
    // watchPosition()

    return (
        <>
            <section className="overflow-hidden text-gray-700 events-container">
                {/*<MapButton*/}
                {/*    url='/percorsi/list'*/}
                {/*/>*/}



                        <div className='relative'>

                        <ListButton
                            url='/percorsi'
                        />
                        <MapContainer
                            center={[37.80964239679445, 12.870139823291876]}
                            zoom={16}
                            // zoomControl={false}
                            // scrollWheelZoom={false}
                            // doubleClickZoom={false}
                            // closePopupOnClick={false}
                            // dragging={false}
                            // zoomSnap={false}
                            // zoomDelta={false}
                            // trackResize={false}
                            // touchZoom={false}
                            // className='h-96'
                            // style={{ height: "calc(100vh - 500px)" }}
                            style={{ height: "calc(100vh - 180px", zIndex: '0' }}
                        >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <LocationMarker />
                        </MapContainer>
                        </div>




                {/*<TestMap/>*/}

                {/*<Routes>*/}

                {/*<Route path="/"*/}
                {/*       exact={true}*/}
                {/*       element={*/}
                {/*    <div className="px-1 py-2 mx-auto mt-6">*/}
                {/*        <div className="flex -m-1 md:-m-2">*/}
                {/*            /!*<Swiper*!/*/}
                {/*            /!*    slidesPerView={2}*!/*/}
                {/*            /!*    spaceBetween={0}*!/*/}
                {/*            { /*    centeredSlides={true}*!/*/}
                {/*            /!*    grabCursor={true}*!/*/}
                {/*            /!*    className="mySwiper"*!/*/}
                {/*            /!*>*!/*/}
                {/*            <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14">*/}
                {/*                <div className="snap-end scroll-mx-6 shrink-0">*/}
                {/*                    <div className="shrink-0 w-3 lg:w-5 md:w-5  sm:w-10 sm:-mr-[2px]"></div>*/}
                {/*                </div>*/}
                {/*                { props.items.map((percorsiList, i) => (*/}
                {/*                    // <SwiperSlide*/}
                {/*                    // >*/}
                {/*                    <PercorsiItem*/}
                {/*                         key={percorsiList.id}*/}
                {/*                        item={percorsiList}*/}
                {/*                        order={i}*/}
                {/*                        last={props.items.length -1}*/}
                {/*                    />*/}
                {/*                    // </SwiperSlide>*/}
                {/*                ))}*/}
                {/*            </div>*/}

                {/*            /!*</Swiper>*!/*/}

                {/*        </div>*/}
                {/*    </div>*/}

                {/*}>*/}
                {/*</Route>*/}
                {/*/!*<Routes>*!/*/}
                {/*    <Route path="/:percorsiId" element={*/}
                {/*        <PercorsoDetail*/}
                {/*            items={props.items}*/}
                {/*        />*/}
                {/*    }>*/}

                {/*    </Route>*/}
                {/*</Routes>*/}
                {/*/!*</Routes>*!/*/}
            </section>
        </>
    );
};

export default PercorsiList;
