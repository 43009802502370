import React, {useState, useEffect} from "react";
import cookies from "js-cookie";
import OpereList from "./OpereList";

import API from '../../api'
import Spinner from "../../UI/Spinner";


const Opere = () => {
    const [opereHighlighted, setOpereHighlighted] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
         // const currentLanguageInterval = setInterval(() => {
            const currentLanguageCode = localStorage.getItem('i18nextLng');

            // if(currentLanguageCode){
            //     clearInterval(currentLanguageInterval)



        async function getOpereHighlighted() {
                setIsLoading(true);
                const response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/homepage/805de81e-94f8-4d4f-843e-f5eccdc8d14b?include=field_opere_in_evidenza`);
                const opereHighlighted = await response.data;

                console.log(opereHighlighted)

                const transformedOpereHighlighted = opereHighlighted.included.map(opereHighlightedData => {
                    return{
                        id: opereHighlightedData.id,
                        title: opereHighlightedData.attributes.title,
                        body: opereHighlightedData.attributes.body,
                        previewId: opereHighlightedData.relationships.field_immagine_anteprima.data.id
                    };
                });

                setOpereHighlighted(transformedOpereHighlighted);
                setIsLoading(false);
            return false

        }
        const myPromise = new Promise((resolve, reject) => {
            resolve('foo');
        });

        myPromise
            .then(getOpereHighlighted)
        // }
        // },100);

    }, []);

    return(
        <section>
            {!isLoading && opereHighlighted.length > 0 &&
                <OpereList
                    opereHighlighted={opereHighlighted}
                />
            }
            {!isLoading && opereHighlighted.length === 0 && <p>No in evidenza data</p>}
            {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
        </section>
    )

}

export default Opere;
