import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './index.css';
import App from './App';
import Spinner from "./components/UI/Spinner";
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        supportedLngs: ['it', 'en'],
        resources: {
            en: {
                translation: {
                    "welcome": "Welcome!",
                    "language": "Language",
                    "novita_title": "News and events",
                    "section_events_title": "Events",
                    "section_news_title": "News",
                    "in_evidenza_title": "In evidence",
                    "quando": "When",
                    "prezzo": "Prices",
                    "dove": "Where is it",
                    "notizia_category": "News",
                    "evento_category": "Event",
                    "scopri_page": "You discover",
                    "museo_category": "Museum",
                    "read_more": "Read everything",
                    "section_musei_title": "The Gibellina Museums",
                    "maps_button": "Map",
                    "list_button": "List",
                    "go_back": "Backwards",
                    "percorsi_title": "Artworks around you",
                    "tempo_label": "Average travel time",
                    "tappe_label": "Average travel time",
                    "opere_title": "Artworks",
                    "benvenuto_gibellina": "Welcome",
                    "benvenuto_gibellina2": "to Gibellina!",
                    "descrizione_benvenuto": "Enjoy your trip and live\n                 exciting experiences\n                 together with us.",
                    "inizia_tap": "Start with a Tap!",
                    "opere_category": "Artworks",
                    "section_doveMangiare_title": "Where to eat",
                    "section_doveDormire_title": "Where to sleep",
                    "menu_opere": "Artworks",
                    "menu_home": "Home",
                    "menu_scopri": "Discover",
                    "menu_il_cretto": "The Cretto",
                    "menu_la_storia": "History",
                    "menu_novita": "News  ",
                    "menu_musei": "Museums",
                    "menu_dove_mangiare": "Where to eat",
                    "menu_dove_dormire": "Where to sleep",
                    "menu_servizi": "Services",
                    "opere_popup_title": "Discover the Artworks of Gibellina",
                    "opere_popup_desc": "Click on the button to access the section relating to the Artworks",
                    "opere_popup_button": "Go to the Artworks",
                    "descrizione": "Description",
                    "contatti": "Contacts",
                    "percorso_maps_button": "Open route in maps"


                }

            },
            it: {
                translation: {
                    "welcome": "Benvenuto!",
                    "language": "Lingua",
                    "novita_title": "Novità ed eventi",
                    "section_events_title": "Eventi",
                    "section_news_title": "Notizie",
                    "in_evidenza_title": "In Evidenza",
                    "quando": "Quando",
                    "prezzo": "Prezzi",
                    "dove": "Dove",
                    "notizia_category": "Notizia",
                    "evento_category": "Evento",
                    "scopri_page": "Scopri",
                    "museo_category": "Museo",
                    "read_more": "Leggi tutto",
                    "section_musei_title": "I Musei di Gibellina",
                    "maps_button": "Mappa",
                    "list_button": "Lista",
                    "go_back": "Indietro",
                    "percorsi_title": "Opere attorno a te",
                    "tempo_label": "Tempo medio di percorrenza",
                    "tappe_label": "Numero di tappe",
                    "opere_title": "Opera",
                    "benvenuto_gibellina": "Benvenuto",
                    "benvenuto_gibellina2": "a Gibellina!",
                    "descrizione_benvenuto": "Goditi il tuo viaggio e vivi\n                esperienze entusiasmanti\n                insieme a noi.",
                    "inizia_tap": "Inizia con un Tap!",
                    "opere_category": "Opera",
                    "section_doveMangiare_title": "Dove Mangiare",
                    "section_doveDormire_title": "Dove Dormire",
                    "section_opere2_title": "Opere",
                    "section_servizi_title": "Altri servizi",
                    "menu_opere": "Opere",
                    "menu_home": "Home",
                    "menu_scopri": "Scopri",
                    "menu_il_cretto": "Il Cretto",
                    "menu_la_storia": "La Storia",
                    "menu_novita": "Novità",
                    "menu_musei": "Musei",
                    "menu_dove_mangiare": "Dove Mangiare",
                    "menu_dove_dormire": "Dove Dormire",
                    "menu_servizi": "Servizi",
                    "opere_popup_title": "Scopri le Opere di Gibellina",
                    "opere_popup_desc": "Clicca sul pulsante per accedere alla sezione relativa alle opere",
                    "opere_popup_button": "Vai alle Opere",
                    "descrizione": "Descrizione",
                    "contatti": "Contatti",
                    "percorso_maps_button": "Apri percorso in maps"
                }
            }
        },
        fallbackLng: "it",
        detection: {
            order: ['localStorage', 'path', 'cookie', 'htmlTag', 'path', 'subdomain'],
            caches:['localStorage', 'cookie'],
        },
    });
const loadingMarkup = (
    <div>
        <h2><Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' /></h2>
    </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={loadingMarkup}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
