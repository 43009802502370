import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import Gallery from "../../UI/gallery/Gallery";
import cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import icon from '../../../icons/marker-icon.png';
import Informazioni from "../../novita/UI/Informazioni";
import CardImgHeader from "../../novita/UI/CardImgHeader";

import './DoveDormireDetail.css'

import API from '../../api'
import BackButton from "../../UI/BackButton";
import Spinner from "../../UI/Spinner";
import Reader from "../../novita/eventi/Reader";
import GoToDirection from "../../UI/GoToDirection";
let DefaultIcon = L.icon({
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

const DoveDormireDetail = (props) => {

    const { t } = useTranslation();

    const params = useParams()
    const [doveDormire, setDoveDormire] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        async function fetchDoveDormireHandler() {
            const currentLanguageCode = localStorage.getItem('i18nextLng')

            setIsLoading(true);
            let response = await API.get(`/${(currentLanguageCode === undefined || currentLanguageCode === null) ? 'it' : currentLanguageCode}/jsonapi/node/dove_mangiare/${params.doveDormireId}?include=field_immagine_anteprima.field_media_image,field_galleria.field_media_image`)

            const dataDoveDormireo = await response.data;
            setError(null)
            const dataDoveDormireoArray = [dataDoveDormireo]


            const transformedDoveDormire = dataDoveDormireoArray.map(doveDormireData => {
                return{
                    id: doveDormireData.data.id,
                    title: doveDormireData.data.attributes.title,
                    body: doveDormireData.data.attributes.body,
                    date_start: doveDormireData.data.attributes.field_data_inizio,
                    date_end: doveDormireData.data.attributes.field_data_fine,
                    latitude: doveDormireData.data.attributes.field_latitudine,
                    longitude: doveDormireData.data.attributes.field_longitudine,
                    preview: dataDoveDormireo.included[1].attributes.uri.url,
                    galleria: dataDoveDormireo.included,
                    audio: dataDoveDormireo.included,
                    dove: doveDormireData.data.attributes.field_dove,
                    prezzi: doveDormireData.data.attributes.field_prezzi,
                    contatti: doveDormireData.data.attributes.contatti,
                    tag: doveDormireData.data.attributes.field_tag_testo,

                };
            });
            setDoveDormire(transformedDoveDormire);
            setIsLoading(false);
        }
        fetchDoveDormireHandler()
    }, [params.doveDormireId]);

    return(
        <div>
            <section>
                <BackButton
                    link={'/scopri/dove-dormire/list'}
                />

                {!isLoading && doveDormire.length > 0 &&
                    <div key={doveDormire.id}>



                        <CardImgHeader
                            className='relative'
                            preview={doveDormire[0].preview}
                            title={doveDormire[0].title}
                            // category={t('museo_category')}
                            tag={(doveDormire[0].tag === null || doveDormire[0].tag === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: doveDormire[0].tag.processed}} />
                            }
                            isLoading={isLoading}
                            data={doveDormire[0]}
                        />

                        <div className='content p-8 lg:px-24 break-words'>
                            <GoToDirection
                                latitude={doveDormire[0].latitude}
                                longitude={doveDormire[0].longitude}
                            />
                            <Reader
                                value={[doveDormire[0].title.replace(/<[^>]*>?/gm, ''), (doveDormire[0].body === null || doveDormire[0].body === undefined) ? '' : doveDormire[0].body.processed.replace(/<[^>]*>?/gm, '')]}
                                body={(doveDormire[0].body === null || doveDormire[0].body === undefined) ? '' : doveDormire[0].body.processed.replace(/<[^>]*>?/gm, '')}
                                audio={doveDormire[0].audio}
                            />
                            <Informazioni
                                dove={doveDormire[0].dove}
                                dateFrom={(doveDormire[0].date_start === null || doveDormire[0].date_start === undefined) ? '' : doveDormire[0].date_start}
                                dateTo={(doveDormire[0].date_end === null || doveDormire[0].date_end === undefined) ? '' : doveDormire[0].date_end}
                                prezzi={doveDormire[0].prezzi}
                            />
                            {/*{(doveDormire[0].body === null || doveDormire[0].body === undefined) ? '' : doveDormire[0].body > 0 &&*/}
                            <div className='description mt-3'>
                                <span className='font-medium mb-3 block lg:text-xl'>{t('descrizione')}</span>
                                {(doveDormire[0].body === null || doveDormire[0].body === undefined) ? '' : <div dangerouslySetInnerHTML={{ __html: doveDormire[0].body.processed}} />}
                            </div>
                            {/*}*/}
                        </div>
                        <div className='galleria'>
                            <section className="overflow-hidden text-gray-700 dove-dormire-container mt-8 mb-9">
                                <div className="px-1 py-2">
                                    <div className="flex -m-1 md:-m-2">
                                        <Gallery
                                            gallery={doveDormire[0].galleria}
                                        >
                                            {props.children}
                                        </Gallery>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {(doveDormire[0].latitude === null || doveDormire[0].latitude === undefined) ? '' : doveDormire[0].latitude > 0 && (doveDormire[0].longitude === null || doveDormire[0].longitude === undefined) ? '' : doveDormire[0].longitude  > 0 &&

                            <MapContainer
                                center={[(doveDormire[0].latitude === null || doveDormire[0].latitude === undefined) ? '' : doveDormire[0].latitude, (doveDormire[0].longitude === null || doveDormire[0].longitude === undefined) ? '' : doveDormire[0].longitude]}
                                zoom={13}
                                scrollWheelZoom={false}
                                className='w-full h-72 lg:h-96 z-0'>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[(doveDormire[0].latitude === null || doveDormire[0].latitude === undefined) ? '' : doveDormire[0].latitude, (doveDormire[0].longitude === null || doveDormire[0].longitude === undefined) ? '' : doveDormire[0].longitude]}>
                                    {/*<Popup>*/}
                                    {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                    {/*</Popup>*/}
                                </Marker>
                            </MapContainer>
                        }
                        {/*<a target='_blank' rel="noreferrer" href={`http://maps.google.com/maps?saddr=50,10&daddr=50,20`}>Test</a>*/}

                    </div>
                }
                {!isLoading && doveDormire.length === 0 && <p>No doveDormire data</p>}
                {isLoading && <Spinner className='flex items-center justify-center w-full fixed top-0 text-center h-screen' />}
                {!isLoading && error && <p>{error.message}</p>}
            </section>
        </div>
    );}

export default DoveDormireDetail;
