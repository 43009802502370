import React, {useEffect} from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
import GalleryItems from "./GalleryItems";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
const Gallery = (props) => {

    console.log(props)

    const arrayTest = props.gallery
    console.log(arrayTest)
    // const result = words.filter(word => word.length > 6);

    const arrayFilter = arrayTest.filter(test => test.type.includes('file--file') )
    const arrayFilterImage = arrayTest.filter(test => test.type.includes('media--image') )
    const arrayFilterImage2 = arrayFilter.filter(test => test.attributes.filemime.includes('image/jpeg') )
    console.log('Filtro file-file', arrayFilter)
    console.log('Filtro media--image', arrayFilterImage)
    console.log('Filtro media/jpeg', arrayFilterImage2)
    useEffect(() => {

        let lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });

        lightbox.on('close', () => {
            console.log('close');


        });
        lightbox.init();
        return () => {
            lightbox.destroy();
            lightbox = null;
        };



    }, []);


    const galleria = []
    arrayFilterImage2.map((item, i) => {
        if(item.attributes.uri !== undefined) {
            galleria.push(item.attributes.uri)
        }
        return(item)
    })
    const galleriaImgProp = []
    arrayFilterImage.map((item, i) => {
        if(item.relationships.thumbnail !== undefined) {
            galleriaImgProp.push(item.relationships.thumbnail.data.meta)
        }
        return(item)
    })

    console.log(galleriaImgProp)

    // if(arrayFilterImage.attributes.uri.length > 0){
    //
    // }
    return(

        <div id='gallery' className='pswp-gallery w-full lg:px-20 lg:-ml-5'>
            {/*<Swiper*/}
            {/*    slidesPerView={2}*/}
            {/*    breakpoints={{*/}
            {/*        // when window width is >= 320px*/}
            {/*        320: {*/}
            {/*            slidesPerView: 2,*/}
            {/*            spaceBetween: 20*/}
            {/*        },*/}
            {/*        // when window width is >= 480px*/}
            {/*        480: {*/}
            {/*            slidesPerView: 2,*/}
            {/*            spaceBetween: 30*/}
            {/*        },*/}
            {/*        // when window width is >= 640px*/}
            {/*        640: {*/}
            {/*            slidesPerView: 2,*/}
            {/*            spaceBetween: 40*/}
            {/*        },*/}
            {/*        992: {*/}
            {/*            slidesPerView: 4,*/}
            {/*            spaceBetween: 40*/}
            {/*        }*/}
            {/*    }}*/}

            {/*    spaceBetween={30}*/}
            {/*    centeredSlides={true}*/}
            {/*    grabCursor={true}*/}
            {/*    className="newGallery w-full"*/}
            {/*>*/}
            <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14">
                <div className="snap-end scroll-mx-6 shrink-0">
                    <div className="shrink-0 w-3 lg:w-5 md:w-5  sm:w-10 sm:-mr-[2px]"></div>
                </div>
                { galleria.map((item, i) => (
                    // <SwiperSlide
                    //     key={i}
                    // >
                    <div className="snap-end scroll-mx-6 shrink-0 w-72"
                         key={i}
                    >

                    <GalleryItems
                            data={item}
                            imgProp={galleriaImgProp}
                            order={i}
                            last={galleria.length -1}
                        />
                    </div>


                    // </SwiperSlide>

                ))}
            </div>
            {/*</Swiper>*/}
        </div>

    );
}

export default Gallery
