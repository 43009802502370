import { Menu, Transition  } from '@headlessui/react'

import i18next from "i18next";
import {useTranslation} from "react-i18next";
import 'flag-icon-css/css/flag-icons.min.css'

const reloadPage = () => {
    window.location.href = '/'
}

const languages = [
    {
        code: 'it',
        name: 'Italiano',
        country_code: 'it'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    }
]
function LanguageDropdown() {
    const { t } = useTranslation();




    return (
        <Menu>
            <Menu.Button className='inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                    <path id="Icon_material-language" data-name="Icon material-language" d="M15.488,3A12.5,12.5,0,1,0,28,15.5,12.494,12.494,0,0,0,15.488,3Zm8.662,7.5H20.463a19.561,19.561,0,0,0-1.725-4.45A10.037,10.037,0,0,1,24.15,10.5ZM15.5,5.55a17.608,17.608,0,0,1,2.387,4.95H13.113A17.608,17.608,0,0,1,15.5,5.55ZM5.825,18a9.778,9.778,0,0,1,0-5H10.05a20.644,20.644,0,0,0-.175,2.5A20.644,20.644,0,0,0,10.05,18ZM6.85,20.5h3.688a19.561,19.561,0,0,0,1.725,4.45A9.984,9.984,0,0,1,6.85,20.5Zm3.688-10H6.85a9.984,9.984,0,0,1,5.413-4.45A19.561,19.561,0,0,0,10.538,10.5ZM15.5,25.45a17.608,17.608,0,0,1-2.387-4.95h4.775A17.608,17.608,0,0,1,15.5,25.45ZM18.425,18h-5.85a18.391,18.391,0,0,1-.2-2.5,18.231,18.231,0,0,1,.2-2.5h5.85a18.231,18.231,0,0,1,.2,2.5A18.391,18.391,0,0,1,18.425,18Zm.313,6.95a19.561,19.561,0,0,0,1.725-4.45H24.15A10.037,10.037,0,0,1,18.738,24.95ZM20.95,18a20.643,20.643,0,0,0,.175-2.5A20.644,20.644,0,0,0,20.95,13h4.225a9.778,9.778,0,0,1,0,5Z" transform="translate(-3 -3)" fill="#fff"/>
                </svg>
            </Menu.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                    <Menu.Items className='absolute right-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100' >
                        <div className='px-1 py-1 '>
                            <h1
                            className='text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium'
                            >
                                {t('language')}
                            </h1>
                            { languages.map(({code, name, country_code}) => (
                                <Menu.Item
                                    key={country_code}
                                    className=' text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm'>
                                    {({ active }) => (
                                        <button
                                            onClick={() => {
                                                i18next.changeLanguage(code);
                                                reloadPage()
                                            }
                                            }
                                            className={`${active && 'bg-blue-500'}`}
                                        >
                                            <span
                                                className={`flag-icon flag-icon-${country_code} mx-2`}
                                            >

                                            </span>
                                            {name}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}

                        </div>
                        {/*<Menu.Item disabled>*/}
                        {/*    <span className="opacity-75">Invite a friend (coming soon!)</span>*/}
                        {/*</Menu.Item>*/}
                    </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default LanguageDropdown;
